<template lang="pug">
.furniture(style='padding-bottom:45px')
  .fur_info
    //- p.title {{detail.name}}
    //- p.bqian(v-show='detail.introduce') {{detail.introduce}}
    //- img(v-bind:src='detail.cover_url')
  .furpic
    //- p.c05(v-show='detailpics.length > 0') 更多详情
    ul.desc
      li(v-for='item in detailpics')
        img(v-bind:src='item.img_url')
  .download(style='position:fixed; bottom:0; width:100%')
    van-button(type='info' block @click='onDownload') 下载      
</template>

<script>
import {SetPreRoute} from '@/lib/helpers/Token'

export default {
  data () {
    return {
      title: '',
      detail: {
        download_url: ''
      },
      detailpics: []
    }
  },
  computed: {
    // 是否已经登录
    power() {
      return this.$store.getters.isLogined()
    },
    companyInfo() {
      return this.$store.state.companyInfo
    },
    userId() {
      return this.$store.state.userInfo.user_poi_users
    }
  },
  methods: {
    async init() {
      const query = this.$route.query
      let rel = {
        relation: [
          {
            table: 'exhibition_img',
            key: 'exhibition_poi_exhibition',
            keys: 'exhibition_poi_exhibition,img_url,sort',
            order: 'sort'
          }
        ]
      }
      let params = {
        where: {
          id: query.id
        },
        with: JSON.stringify(rel)
      }
      try {
        const data = await this.$api.GetExhibition(params)
        this.detail = data.data.items[0]
        this.detailpics = this.detail.exhibition_rel_exhibition_img.items || []
        this.title = this.detail.name
    } catch (error) {
        console.log(error)
      }
    },

    // 下载文件
    onDownload() {
      
      if(!this.power) {
        this.$dialog.confirm({
          title: '',
          message: '登录后可下载，是否登录？',
        }).then(async () => {
          let {params, query} = this.$route
          SetPreRoute({name: 'exhibitionscenedetail', params, query})
          this.$router.push({name: 'login'})
        }).catch(() => {})
      } else {
        if(this.detail.download_url) {
          try{
            window.open(this.detail.download_url)
            let params = {
              com_id_poi_companys: this.companyInfo.id,
              user_poi_users: this.userId,
              action: 'download',
              source_name: 'exhibition',
              file_url: this.detail.download_url,
              source_id: this.detail.id,
              path: window.location.href,
            }
            // 统计用户下载的次数
            this.$api.PostStatisticsNew(params)
          }catch(ex) {
            console.log(ex)
          }
        } else {
          this.$notify({
            message: '资源未找到,请联系管理员.',
            type: 'warning'
          })
        }
      }
    }
  },
  async mounted () {
    await this.init()
    this.$emit('on-nav', {
      leftText: '返回',
      title: this.title,
      rightText: '主页',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      },
      onClickRight: () => {
        this.$router.push({name: 'home'})
      }
    })
  },
}
</script>

<style scoped>
  .furdetail {
    overflow: hidden;
    padding: 0
  }
  .furdetail .mui-navigate-right {
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin:0
  }
  .furdetail p{
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin: 0
  }
  .desc{
    overflow: hidden;
    padding: 0 20px;
  }
  .furdetail .desc li {
    border-bottom: 1px dashed #ccc;
    float: left;
    list-style: none;
    padding-left: 35px;
    width: 50%;
    height: 30px;
    line-height: 30px;
  }
  .furpic ul li:last-child{
    margin-bottom: 0
  }
  .furdetail .desc li span {
    font-size: 12px;
    color: #616161;
  }
  .furdetail .desc li span:nth-child(2) {
    padding-left: 5px;
  }
  .furpic {
    background: #fff;
    position: relative;
    padding-bottom: 10px;
  }
  .furpic p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    text-align: center;

  }
  .furpic ul {
    padding: 0
  }
  .furpic ul li{
    padding: 0;
    overflow: hidden;
  }
  .furpic img{
    float: left;
    display: block;
    width: 100%;
  }
  
  .furpic p.text {
    margin: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 2px;
    line-height: normal;
    height: auto;
    text-align: left;
  }
  .fur_info {
    padding: 0 20px 20px;
    background: #fff;
  }
  .fur_info img{
   width: 100%;
  }
  .fur_info .title {
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    color: #050505;
    text-align: center;
    margin: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bqian{
    font-size: 14px;
    color: #ABABAB;
    border-radius: 2px;
    padding: 0;
    height: 24px;
    line-height: 24px;
    margin: 0
  }
</style>
